import './App.css';
import Modal from 'react-modal'
import { useState } from 'react';
import Lottie from 'lottie-react';
import congratsAnimation from './images/congratsAnimation.json';
import whatBeamer from './images/what.png'
import beamerLogo from './images/beamerLogo.png';
import beamerCharacter from './images/beamerCharacter.png';
import beamingCharacter1 from './images/beamingCharactor1.png';
import beamingCharacter2 from './images/beamingCharactor2.png';
import beamingCharacter3 from './images/beamingCharactor3.png';
import beamingCharacter4 from './images/beamingCharactor4.png';
import raribleLogo from './images/rarible-logo.png';

import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, collection } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getAnalytics } from 'firebase/analytics';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore'

import { ethers } from 'ethers'
import { useEffect } from 'react';

const rpcUrl = 'https://api.kroma.network';
const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
const winningPrizeWallet = '0x45227437fBCE239a63854eb131f002b6A9B71591';
const beamerAddress = '0x6f4893334039134791D24AAD71758E79E3Fee251';
const beamerAbi = [
  "function balanceOf(address owner) public view returns (uint256)"
]
const beamerContract = new ethers.Contract(beamerAddress, beamerAbi, provider);

const firebaseConfig = {
  apiKey: "AIzaSyCKv7E7LDPKzDRx7ynB7bon0rnhvwYx_-8",
  authDomain: "ocd-beamers.firebaseapp.com",
  projectId: "ocd-beamers",
  storageBucket: "ocd-beamers.appspot.com",
  messagingSenderId: "832802778335",
  appId: "1:832802778335:web:cd50a2130caee86278cd6a",
  measurementId: "G-3PDPWDNZN6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);
const addwinner = httpsCallable(functions, 'addwinner');

Modal.setAppElement('#root');

function App() {
  const beamingImages = [beamingCharacter1, beamingCharacter2, beamingCharacter3, beamingCharacter4];
  const [currentImage, setCurrentImage] = useState(beamerCharacter);
  const [count, setCount] = useState(0);
  const [walletAddress, setWalletAddress] = useState('');
  const [txid, setTxid] = useState('');
  const [prizeBalance, setPrizeBalance] = useState(-1);

  // Modals
  const [nftModalStep, setNftModalStep] = useState(0); // 0: hidden, 1: congrats, 2: input wallet, 3: show txid
  const [nftModalIsOpen, setNftModalIsOpen] = useState(false);
  const [winnersModalIsOpen, setWinnersModalIsOpen] = useState(false);
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false);

  // Firestore
  const totalCountRef = doc(db, 'totalCounts', 'dLXKhx37IlFPH9hHa2NG');
  const [totalCount, loadingTotalCount, errorTotalCount] = useDocument(
    totalCountRef,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )
  const winnersRef = collection(db, 'winners');
  const [winners, loadingWinners, errorLoadingWinners] = useCollection(
    winnersRef,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  // Preload & Get Prize Balance from Beamer Contract
  useEffect(() => {
    // Preload beaming images
    const imgList = [beamingCharacter1, beamingCharacter2, beamingCharacter3, beamingCharacter4]
    imgList.forEach(image => {
      new Image().src = image
    })
    async function getPrizeBalance() {
      const balance = await beamerContract.balanceOf(winningPrizeWallet);
      setPrizeBalance(balance.toNumber())
    }
    getPrizeBalance();
  }, [])

  // Click Event Handlers
  const handleMouseDown = () => {
    const randomIndex = Math.floor(Math.random() * beamingImages.length);
    setCurrentImage(beamingImages[randomIndex]);
  };

  const handleMouseUp = async () => {
    setCurrentImage(beamerCharacter);
    setCount(count + 1);
    await setDoc(totalCountRef, {
      result: totalCount.data().result + 1
    })

    if (prizeBalance > 0 && Math.random() < 0.001) {
      setNftModalStep(1);
      setNftModalIsOpen(true);
    }
  }

  const handleTouchEnd = () => {
    setCurrentImage(beamerCharacter);
  }

  // Modals
  const showWinnersModal = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setWinnersModalIsOpen(true);
  }

  const showAboutModal = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setAboutModalIsOpen(true);
  }

  const closeAboutModal = (e) => {
    setAboutModalIsOpen(false);
  }

  const closeNftModal = () => {
    setNftModalIsOpen(false);
    setNftModalStep(0);
  };

  const closeWinnersModal = () => {
    setWinnersModalIsOpen(false);
  };

  // Wallet Submit
  const handleWalletSubmit = () => {
    setNftModalStep(3);

    addwinner({ address: walletAddress })
      .then((result) => {
        const data = result.data;
        const txid = data.txid;
        setTxid(txid);
        setWalletAddress('');
      })
  };

  // Utils
  const shortenAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  }

  return (
    <div className="bg-[#07080D]/90 w-full min-h-screen relative">
      <header className="w-full h-[80px] absolute top-0 left-0">
        <img src={beamerLogo} alt="Beamer Logo" className="w-[320px] h-[80px] m-auto" />
      </header>
      <div
        className="relative pt-8 min-h-screen flex items-center justify-center"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchEnd={handleTouchEnd}
      >
        <div className="relative">
          <p
            className='fugaz-one-regular text-3xl text-white absolute top-[-60px] sm:top-[-40px] left-0 right-0 mx-auto text-center underline cursor-pointer'
            onClick={showAboutModal}
          >
            {prizeBalance < 0 && <span>Loading...</span>}
            {prizeBalance >= 0 && `Remaining NFTs: ${prizeBalance}`}
          </p>
          <p className='fugaz-one-regular text-5xl text-[#2EFDF0] absolute top-2 sm:top-8 left-0 right-0 mx-auto text-center'>{count}</p>
          <div
            style={{ backgroundImage: `url(${currentImage})` }}
            className="w-[330px] h-[330px] m-auto sm:w-[600px] sm:h-[600px] bg-center bg-no-repeat bg-contain"
          />
          <p className="text-center text-3xl text-white fugaz-one-regular">
            {errorTotalCount && <strong>Error: {JSON.stringify(errorTotalCount)}</strong>}
            {loadingTotalCount && <span>Total: Loading...</span>}
            {totalCount && `Total: ${JSON.stringify(totalCount.data().result)}`}
          </p>
          <p
            className="text-center text-xl text-white underline fugaz-one-regular cursor-pointer"
            onClick={showWinnersModal}
          >
            Winners
          </p>
          <a
            href="https://rarible.com/beamersnft"
            target='_blank'
            rel='noreferrer'
            className='mx-auto w-[240px] h-[48px] rounded-lg bg-[#12BDB2] mt-8 flex justify-center items-center fugaz-one-regular text-black text-xl cursor-pointer'
          >
            Buy on Rarible
            <div
              style={{ backgroundImage: `url(${raribleLogo})` }}
              className="w-8 h-8 ml-4 bg-center bg-no-repeat bg-contain"
            />
          </a>
        </div>
      </div>
      <Modal
        isOpen={nftModalIsOpen}
        onRequestClose={closeNftModal}
        contentLabel="Congratulations"
        className="z-10 w-[350px] h-[550px] bg-white rounded-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 text-center"
      >
        {nftModalStep === 1 && (
          <div className="congrats-step">
            <h2 className='fugaz-one-regular text-3xl mt-8'>Congratulations!</h2>
            <Lottie animationData={congratsAnimation} loop={true} />
            <button
              onClick={() => setNftModalStep(2)}
              className='w-20 h-9 bg-[#2EFDF0] text-black rounded-md mt-4 fugaz-one-regular cursor-pointer'
            >
              Next
            </button>
          </div>
        )}
        {nftModalStep === 2 && (
          <div className="wallet-step">
            <h2 className='fugaz-one-regular text-3xl mt-8'>Congratulations!</h2>
            <img src={whatBeamer} alt="WhatBeamer" />
            <p className='fugaz-one-regular text-base text-black text-center'>Submit your Kroma wallet address to receive your Beamer</p>
            <div className='mt-4'>
              <input
                type="text"
                value={walletAddress}
                onChange={(e) => setWalletAddress(e.target.value)}
                placeholder="Enter wallet address (e.g. 0x1234...)"
                className='w-[300px] h-[40px] border-2 border-black rounded-md px-2'
              />
            </div>
            <button
              onClick={handleWalletSubmit}
              className='w-20 h-9 bg-[#2EFDF0] text-black rounded-md mt-2 fugaz-one-regular cursor-pointer'
            >
              Submit
            </button>
          </div>
        )}
        {nftModalStep === 3 && (
          <div className="txid-step">
            <h2 className='fugaz-one-regular text-3xl mt-8'>Congratulations!</h2>
            <img src={whatBeamer} alt="WhatBeamer" />
            <p className='fugaz-one-regular text-base text-black text-center'>Your Beamer has been sent! Check on Kromascan</p>
            <p className='lexend text-base text-black text-center'>
              {txid === '' && `Txid: Sending...`}
              {txid !== '' && (
                <>
                  <span>Txid: </span>
                  <a
                    className="underline"
                    href={`https://kromascan.com/tx/${txid}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {shortenAddress(txid)}
                  </a>
                </>
              )}
            </p>
            <button
              onClick={closeNftModal}
              className='w-20 h-9 bg-[#2EFDF0] text-black rounded-md mt-2 fugaz-one-regular cursor-pointer'
            >
              Close
            </button>
          </div>
        )}
      </Modal>
      <Modal
        isOpen={winnersModalIsOpen}
        onRequestClose={closeWinnersModal}
        contentLabel="Winners List"
        className="z-10 w-[350px] h-[520px] bg-white rounded-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 text-center"
      >
        <h2 className='fugaz-one-regular text-3xl mt-8'>Winners</h2>
        <ul className='lexend mt-8 h-80 overflow-auto'>
          {errorLoadingWinners && <strong>Error: {JSON.stringify(errorLoadingWinners)}</strong>}
          {loadingWinners && <span>Loading Winners</span>}
          {winners && (
            winners.docs.map((doc) => {
              const winner = doc.data();
              return <li key={doc.id}>{shortenAddress(winner.address)}</li>
            })
          )}
        </ul>
        <button
          onClick={closeWinnersModal}
          className='w-20 h-9 bg-[#2EFDF0] text-black rounded-md mt-4 fugaz-one-regular cursor-pointer'
        >
          Close
        </button>
      </Modal>
      <Modal
        isOpen={aboutModalIsOpen}
        onRequestClose={closeAboutModal}
        contentLabel="About"
        className="z-10 w-[350px] h-[520px] bg-white rounded-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 text-center"
      >
        <h2 className='fugaz-one-regular text-3xl mt-8'>About</h2>
        <p className='mt-8 mx-2 lexend text-base text-black text-center'>You have a 0.1% chance to win a Beamer NFT by clicking the Beamer. If you win, simply submit your Kroma wallet address to receive your free NFT. There’s no need to send any transaction—it's completely free.</p>
        <div className="mt-4">
          <a
            className='mt-8 mx-2 lexend text-base text-black text-center underline'
            href="https://x.com/beamersnft"
            target='_blank'
            rel="noreferrer"
          >
            Follow Beamer on X
          </a>
        </div>
        <button
          onClick={closeAboutModal}
          className='w-20 h-9 bg-[#2EFDF0] text-black rounded-md mt-8 fugaz-one-regular cursor-pointer'
        >
          Close
        </button>
      </Modal>
    </div>
  );
}

export default App;
